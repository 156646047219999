
import { Grid, GridItem, IconButton, Select, Spinner } from "@chakra-ui/react"
import { FaDownload } from "react-icons/fa6"
import { useApi } from "../../hooks/useApi";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { NotificationContext } from "../../components/NotificationPane";
import { NotificationContextType } from "../../types";

type FlexInfoSelectorProps = {
  selectedFlexModel: string;
  setSelectedFlexModel: (option: string) => void;
  selectedFlexOption: string;
  setSelectedFlexOption: (option: string) => void;
  handleExport: () => void;
  selectedTabIndex: number;
};

function FlexInfoSelector({ selectedFlexModel, setSelectedFlexModel, selectedFlexOption, setSelectedFlexOption, handleExport, selectedTabIndex }: FlexInfoSelectorProps) {
  const { pushNotification } = useContext<NotificationContextType | null>(NotificationContext)!;
  const {ready, getFlexOptions} = useApi();

  const [modelOptionList, setModelOptionList] = useState<{ [key: string]: string[] }>({});

  useEffect(() => {
      if (ready) loadOptionList();
  }, [ready, getFlexOptions]);

  async function loadOptionList() {
    try {
      const responseJSON = await getFlexOptions();
      setModelOptionList(responseJSON);
    } catch {
      pushNotification("Could not flex options.", "error")
    }
  }

  function handleModelSelect(e: ChangeEvent<HTMLSelectElement>){
    const val = e.target.value;
    setSelectedFlexOption(""); // clear flex group selection when parent dropdown changed - awaits new option selection
    setSelectedFlexModel(val);
  }

  if (!modelOptionList || Object.keys(modelOptionList).length < 1) return <Spinner />
  const ignoreModel = (Object.keys(modelOptionList).length === 1);
  if (ignoreModel) setSelectedFlexModel(Object.keys(modelOptionList)[0]);
  return (
    <Grid templateColumns='repeat(3, 1fr)' h='auto'>
      <GridItem >
        {!ignoreModel &&
          <Select placeholder='Select model' value={selectedFlexModel} onChange={handleModelSelect} maxW={550} disabled={selectedTabIndex > 1}>
            {Object.keys(modelOptionList).map(option => 
              <option key={option} value={option} selected={ignoreModel} hidden={ignoreModel}>{option}</option>
            )}
          </Select>
        }
      </GridItem>
      <GridItem display="flex" justifyContent="center">
        {selectedFlexModel &&
          <Select placeholder='Select group' value={selectedFlexOption} onChange={e => setSelectedFlexOption(e.target.value)} maxW={550} disabled={selectedTabIndex > 1}>
            {selectedFlexModel && modelOptionList[selectedFlexModel].map(option => 
              <option key={option} value={option}>{option}</option>
            )}
          </Select>
        }
      </GridItem>
      <GridItem display="flex" justifyContent="flex-end">
        {selectedTabIndex == 0 &&
          <IconButton icon={<FaDownload />} onClick={handleExport} aria-label={'Download'}>Export</IconButton>
        }
      </GridItem>
    </Grid>
  )
}

export default FlexInfoSelector