import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import { ColDef } from "ag-grid-enterprise";
import { FlexPositionType } from "../../types"
import { useMemo, useState } from "react";
import { caseInsensitiveComparator, kwhValueFormatter, penceValueFormatter, percentValueFormatter, poundValueFormatter, timezoneIndependantDateComparator } from "../../util/AgGridFunctions";
import moment from 'moment';
import { AbsoluteCenter } from '@chakra-ui/react';

type FlexGraphPropTypes = {
  flexPosition: FlexPositionType[] | undefined;
}

function FlexGrid({ flexPosition }: FlexGraphPropTypes) {

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs] = useState<ColDef<FlexPositionType>[]>([
    { field: "cmonth", width: 150, headerName: "Period", valueFormatter: (params => moment(params.value).format('MMM-YYYY')), filter: 'agDateColumnFilter', filterParams: { comparator: timezoneIndependantDateComparator },  },
    { field: "mth_kwh", width: 150, headerName: "Mth kWh", valueFormatter: kwhValueFormatter, filter: 'agNumberColumnFilter' },
    { field: "trd_vol", width: 150, headerName: "Purchased", valueFormatter: percentValueFormatter, filter: 'agNumberColumnFilter'  },
    { field: "PureCom_cost", width: 150, headerName: "Pure Com", valueFormatter: poundValueFormatter, filter: 'agNumberColumnFilter'  },
    { field: "est_flt", width: 150, headerName: "Non Com", valueFormatter: poundValueFormatter, filter: 'agNumberColumnFilter'  },
    { field: "Stg_Cap_Cost", hide: true, headerName: "Std & Cap", valueFormatter: poundValueFormatter, filter: 'agNumberColumnFilter'  },
    { field: "cap_mth", width: 150, headerName: "Capacity", valueFormatter: poundValueFormatter, filter: 'agNumberColumnFilter' },
    { field: "stg_mth", width: 150, headerName: "Standing", valueFormatter: poundValueFormatter, filter: 'agNumberColumnFilter' },
    { field: "nom_eaq", hide: true, width: 225, headerName: "Annual Nominated Consumption", valueFormatter: kwhValueFormatter, filter: 'agNumberColumnFilter' },
    { field: "est_trd", width: 150, headerName: "Est Traded", valueFormatter: poundValueFormatter, filter: 'agNumberColumnFilter' },
    { field: "est_open", width: 150, headerName: "Est Open", valueFormatter: poundValueFormatter, filter: 'agNumberColumnFilter' },
    { field: "cost_mkt", width: 150, headerName: "Market Price", valueFormatter: penceValueFormatter, filter: 'agNumberColumnFilter'  },
    { field: "dvr", width: 200, headerName: "Delivered Rate", valueFormatter: penceValueFormatter, filter: 'agNumberColumnFilter'  },
    { field: "mdays", headerName: "Days", width: 125, hide: true  },
  ]);

  const defaultColDef: ColDef = useMemo(() => ({
    filter: 'agMultiColumnFilter',
    enableRowGroup: true,
    enableValue: true,
    comparator: caseInsensitiveComparator,
  }), []);
  
  if (!flexPosition || flexPosition.length === 0) return <AbsoluteCenter>Please select a model and group option in the top bar.</AbsoluteCenter>
  return (
    <div
      className="ag-theme-quartz" // applying the Data Grid theme
      style={{ height: '95%', width: '100%' }} // the Data Grid will fill the size of the parent container
    >
    <AgGridReact<FlexPositionType>
        rowData={flexPosition}
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        suppressAggFuncInHeader={true}
        rowGroupPanelShow={"always"}
        groupDisplayType={'groupRows'}
        groupAllowUnbalanced
    />
    </div>
  )
}

export default FlexGrid