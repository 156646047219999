import React from "react";
import ReactDOM from "react-dom/client";
import Router from './Router.tsx'
import { PublicClientApplication, EventType, AuthenticationResult } from "@azure/msal-browser";
import { msalConfig } from "./auth/authConfig.ts";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://b4ef831a67f47991e939510c79ce1bf7@o4507814927663104.ingest.de.sentry.io/4507814929563728",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  enabled: import.meta.env.VITE_SENTRY_ENABLED === "true",
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost",/^https:\/\/api(-development)?\.eyeserve\.co\.uk/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const msalInstance = new PublicClientApplication(msalConfig);

await msalInstance.initialize();

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    const payload = event.payload as AuthenticationResult;
    if (payload && payload.account) {
      msalInstance.setActiveAccount(payload.account);
    }
  }
});


ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Router msalInstance={msalInstance} />
  </React.StrictMode>,
);
