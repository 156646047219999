import React, { useState, ReactNode, useRef, useEffect } from 'react';
import {
  Box,
  Flex,
  Icon,
  Text,
  HStack,
  VStack,
  useDisclosure,
  useBreakpointValue,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { FaBuilding, FaChartLine, FaFile, FaUser, FaArrowRightFromBracket, FaWrench, FaHouse, FaPenToSquare, FaThumbtack, FaCalendarDays, FaEye, FaBug, FaDatabase, FaF, FaLightbulb  } from 'react-icons/fa6';
import { Link, useLocation } from 'wouter';
import { useAuth } from '../hooks/useAuth';
import eyebrightFlower from '../assets/eyebright-flower.png';
import RenewalsDrawer from './RenewalsDrawer';
import { FaBars } from "react-icons/fa6";
import TicketsDrawer from './TicketsDrawer';
import ActingAsModal from './ActingAsModal';
import { useCurrentUser } from '../hooks/useCurrentUser';
import WhatsNewModal from './WhatsNewModal';

const menuOptions = [
  {
    name: 'Home',
    href: '/',
    icon: FaHouse,
    internalCustomerOnly: false,
  },
  {
    name: 'Charts',
    href: '/hh-dashboard',
    icon: FaChartLine,
    internalCustomerOnly: false,
  },
  {
    name: 'Site Summary',
    href: '/site-summary',
    icon: FaBuilding,
    internalCustomerOnly: false,
  },
  {
    name: 'Files',
    href: '/files',
    icon: FaFile,
    internalCustomerOnly: true,
  },
  {
    name: 'Market Data',
    href: '/market-data',
    icon: FaDatabase,
    internalCustomerOnly: true,
  },
  {
    name: 'Flex',
    href: '/flex',
    icon: FaF,
    internalCustomerOnly: true,
  },
]

const iconSize = 4;


interface SubmenuItem {
  href: string;
  text: string;
  icon?: React.ElementType;
}

interface NavItemProps {
  icon?: React.ElementType;
  collapsed?: boolean;
  href?: string;
  text: string;
  color?: string;
  ticketsOpen?: boolean;
  renewalsOpen?: boolean;
  onClick?: () => void;
  submenu?: SubmenuItem[]; // New prop for submenu
}

interface SidebarProps {
  children: ReactNode;
}

interface PinButtonProps {
  onToggle: () => void;
  pinned: boolean;
}

const NavItem: React.FC<NavItemProps> = ({
  icon,
  collapsed,
  href,
  text,
  color,
  ticketsOpen,
  renewalsOpen,
  submenu,
  onClick
}) => {
  const [location] = useLocation();
  const subMenuIsActive = submenu && submenu.some(item => item.href === location);
  const isActive = href === location || ticketsOpen || renewalsOpen || subMenuIsActive;
  const [submenuOpen, setSubmenuOpen] = useState(false);

  return (
    <Box 
      onMouseOver={() => setSubmenuOpen(true)}
      onMouseLeave={() => setSubmenuOpen(false)}
    >
      <Link href={href || "#"}>
        <HStack
          onClick={onClick} 
          m={2}
          bg={isActive ? "white" : color}
          p={3}
          h={8}
          w={collapsed ? "fit-content" : "auto"}
          borderRadius="md"
        >
          {icon && (
            <Icon
              boxSize="20px"
              as={icon}
              color={isActive ? color : "white"}
            />
          )}
          {!collapsed && (
            <Text fontSize="md" color={isActive ? color : "white"}>
              {text}
            </Text>
          )}
        </HStack>
      </Link>

      {/* Render submenu if submenuOpen is true */}
      {submenu && submenu.length > 0 && submenuOpen && (
        <VStack
          spacing={1}
          align="start"
          pl={8} // Indentation for submenu items
        >
          {submenu.map((item, index) => (
            <Link key={index} href={item.href}>
              <HStack
                borderRadius="md"
                p={2}
                bg={location === item.href ? "white" : color}
              >
                <Text fontSize="md" color={location === item.href ? color : "white"}>{item.text}</Text>
              </HStack>
            </Link>
          ))}
        </VStack>
      )}
    </Box>
  );
};

const PinButton: React.FC<PinButtonProps> = ({ onToggle, pinned }) => {
  return (
    <Box m={2} p={2} onClick={onToggle}>
      <Icon
        boxSize={iconSize}
        as={FaThumbtack}
        transform={pinned ? "rotate(90deg)" : "rotate(0deg)"}
        transition="transform 0.2s ease-in-out"
        color="white"
      />
    </Box>
  )
};

const DynamicMenu: React.FC<SidebarProps> = ({ children }) => {
  const type = useBreakpointValue(
    {
      base: 'BURGER',
      sm: 'BURGER',
      md: 'SIDEBAR',
    }
  )

  if (type === 'SIDEBAR') {
    return (
      <Sidebar>
        {children}
      </Sidebar>
    )
  }

  if (type === 'BURGER') {
    return (
      <BurgerMenu>
        {children}
      </BurgerMenu>
    )
  }
}

const BurgerMenu: React.FC<SidebarProps> = ({ children }) => {
  const { isOpen: isTicketsOpen, onToggle: onTicketsToggle, onClose: onTicketsClose } = useDisclosure(); // tracks status of tickets drawer
  const { isOpen: isRenewalsOpen, onToggle: onRenewalsToggle, onClose: onRenewalsClose } = useDisclosure(); // tracks status of renewals drawer
  const { isOpen: isWhatsNewOpen, onToggle: onWhatsNewToggle, onClose: onWhatsNewClose } = useDisclosure(); // tracks status of whats new modal
  const { isOpen: isActingAsOpen, onToggle: onActingAsToggle, onClose: onActingAsClose } = useDisclosure(); // tracks status of acting as modal
  const { user, customerType } = useCurrentUser();

  const showFlex = Number(user?.showFlex) > 0;

  const filteredOptions = menuOptions
  // Keep if not internal OR if the customer type is 'EYEBRIGHT'
  .filter(option => !option.internalCustomerOnly || customerType === 'EYEBRIGHT')
  // Keep if it's not 'Flex' OR (it is 'Flex' but showFlex is true)
  .filter(option => option.name !== 'Flex' || showFlex);

  return (
    <>
      <TicketsDrawer  isOpen={isTicketsOpen} onClose={onTicketsClose} />
      <RenewalsDrawer isOpen={isRenewalsOpen} onClose={onRenewalsClose} />
      <WhatsNewModal isOpen={isWhatsNewOpen} onClose={onWhatsNewClose}></WhatsNewModal>
      <ActingAsModal isOpen={isActingAsOpen} onClose={onActingAsClose}></ActingAsModal>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label='Menu'
          size='lg'
          icon={<FaBars />}
          position="fixed" 
          top="0" 
          left="0" 
          m={4} 
          zIndex="1"
        />
        <MenuList>
          {filteredOptions.map((option) => (
              <Link href={option.href} key={option.name}>
                <MenuItem>
                  {option.name}
                </MenuItem>
              </Link>
          ))}
          {customerType === 'EYEBRIGHT' && 
            <Link href="#" onClick={onTicketsToggle} key={"Tickets"}><MenuItem>Tickets</MenuItem></Link>
          }
          <Link href="#" onClick={onRenewalsToggle} key={"Contract Renewals"}><MenuItem>Contract Renewals</MenuItem></Link>
          {/* WHATS NEW UPDATE HIDDEN ON SIDEBAR AND BURGER MENU - CHANGE BOTH */}
          <Link href="#" onClick={onWhatsNewToggle} key={"Whats New"} hidden><MenuItem>What's New?</MenuItem></Link>
          { user?.emailaddress.includes("@eyebrightutilities.co.uk") &&
            <Link href="#" onClick={onActingAsToggle} key="Acting As"><MenuItem>Acting As</MenuItem></Link>
          }
        </MenuList>
      </Menu>
      <Box>
        {children}
      </Box>
    </>
  )
}

const Sidebar: React.FC<SidebarProps> = ({ children }) => {
  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const [sidebarWidth, setSidebarWidth] = useState<number>(0);
  const [collapsed, setCollapsed] = useState(true);
  const [pinned, setPinned] = useState(false);
  const { logout } = useAuth();
  const { user, actingAs, isAdmin, isCustomerAdmin, customerType, isConsultant } = useCurrentUser();

  const showCostSummary = Number(user?.showCostSummary) > 0;
  const showFlex = Number(user?.showFlex) > 0;

  useEffect(() => {
    if (sidebarRef.current && (pinned || collapsed)) {
      setSidebarWidth(sidebarRef.current.offsetWidth);
    }
  }, [collapsed, pinned]);

  const onMouseLeave = () => {
    if (!pinned)
      setCollapsed(true);
  }

  const { onClose } = useDisclosure(); // admin selection uses
  const { isOpen: isTicketsOpen, onToggle: onTicketsToggle, onClose: onTicketsClose } = useDisclosure(); // tracks status of tickets drawer
  const { isOpen: isRenewalsOpen, onToggle: onRenewalsToggle, onClose: onRenewalsClose } = useDisclosure(); // tracks status of renewals drawer
  const { isOpen: isWhatsNewOpen, onToggle: onWhatsNewToggle, onClose: onWhatsNewClose } = useDisclosure(); // tracks status of whats new modal
  const { isOpen: isActingAsOpen, onToggle: onActingAsToggle, onClose: onActingAsClose } = useDisclosure(); // tracks status of acting as modal
  
  const filteredOptions = menuOptions
  // Keep if not internal OR if the customer type is 'EYEBRIGHT'
  .filter(option => !option.internalCustomerOnly || customerType === 'EYEBRIGHT')
  // Keep if it's not 'Flex' OR (it is 'Flex' but showFlex is true)
  .filter(option => option.name !== 'Flex' || showFlex);

  return (
    <>
      <TicketsDrawer  isOpen={isTicketsOpen} onClose={onTicketsClose} />
      <RenewalsDrawer isOpen={isRenewalsOpen} onClose={onRenewalsClose} />
      <WhatsNewModal isOpen={isWhatsNewOpen} onClose={onWhatsNewClose}></WhatsNewModal>
      <ActingAsModal isOpen={isActingAsOpen} onClose={onActingAsClose}></ActingAsModal>
      <Box>
        <Box
          ref={sidebarRef}
          display={"flex"}
          bg='#6A7D80'
          height="100vh"
          position="fixed"
          top="0"
          left="0"
          zIndex="100000"
          onMouseEnter={() => setCollapsed(false)}
          onMouseLeave={onMouseLeave}
          className='custom-scroll'
        >
          <Flex
            direction="column"
            justifyContent="space-between"
            height="100%"
          >
          <VStack align='left'>
            <HStack justifyContent="space-between">
              <Box m={2} p={2}>
                <img src={eyebrightFlower} alt="Eyebright Flower" width="30" />
              </Box>
              {!collapsed &&
                <PinButton pinned={pinned} onToggle={() => setPinned(!pinned)} />
              }
            </HStack>
            {
              filteredOptions.map((option) => (
                <NavItem
                  key={option.name}
                  href={option.href}
                  text={option.name}
                  icon={option.icon}
                  color="#6A7D80"
                  collapsed={collapsed}
                  onClick={() => {
                    onRenewalsClose();
                    onTicketsClose();
                  }}
                  submenu={
                    showCostSummary && option.name === 'Charts'
                      ? [
                          { href: '/hh-dashboard', text: 'Consumption' },
                          { href: '/cost', text: 'Cost' },
                        ]
                      : undefined
                  }
                />
              ))
            }
            {customerType === 'EYEBRIGHT' && 
              <NavItem color='#6A7D80' icon={FaPenToSquare} collapsed={collapsed} onClick={onTicketsToggle} ticketsOpen={isTicketsOpen} text="Tickets" />
            }
            <NavItem color='#6A7D80' icon={FaCalendarDays} collapsed={collapsed} onClick={onRenewalsToggle} renewalsOpen={isRenewalsOpen} text="Contract Renewals" />
          </VStack>
          <Box marginTop={'auto'} />
          {/* WHATS NEW UPDATE HIDDEN ON SIDEBAR AND BURGER MENU - CHANGE BOTH */}
          {false &&
            <NavItem color='#6A7D80' icon={FaLightbulb} collapsed={collapsed} onClick={onWhatsNewToggle} text="What's New?" />
          }
          { (isAdmin || isConsultant) &&
            <NavItem color='#6A7D80' icon={FaEye} collapsed={collapsed} onClick={onActingAsToggle} text={(!actingAs || actingAs === user?.emailaddress) ? "(Acting As)" : actingAs} />
          }
          <VStack align='left' bg="#566669">
            <NavItem color='#566669' icon={FaUser} collapsed={collapsed} text={user?.firstname || "User"} />
            <NavItem color='#566669' onClick={logout} icon={FaArrowRightFromBracket} collapsed={collapsed} text="Log Out" />
            {(isAdmin || isCustomerAdmin) &&
              <NavItem color='#566669' icon={FaWrench} collapsed={collapsed} onClick={onClose} href="/admin" text="Admin" />
            }
            <NavItem color='#566669' icon={FaBug} collapsed={collapsed} text={"Bug Report"} onClick={() => window.open("https://forms.office.com/Pages/ResponsePage.aspx?id=uw3-VWNTMkSweQqCaCSzLbZl_UfE_nlDrB6zzExfEqZUN1cwU1BNRzdUUEEwOTgzMlBSUlNQRUxDUy4u", '_blank')} />
          </VStack>
        </Flex>
      </Box>
      {sidebarWidth &&
        <Box backgroundColor={"purple.10"} ml={`${sidebarWidth}px`}>
          {children}
        </Box>
      }
      </Box>
    </>
    
  );
};

export default DynamicMenu;
